<template>
<div class="c-app flex-row align-items-center bg-login">
    <CContainer>
        <b-row class="justify-content-center">
            <transition appear name="bounce">
                <b-col xl="4" lg="4" md="6" sm="8">
                    <CCardGroup>
                        <CCard class="p-4" style="background: #75a9a9;border-color: #75a9a9;">
                            <CCardBody>
                                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(login)">
                                        <div class="text-center mt-2 mb-4">
                                            <!-- <img src="img/logo_sig_black.png"
                                        alt="logo sig" width="200" > -->
                                            <img v-if="render" :src="datosCliente.logo" alt="logo sig" width="300px" class="img-fluid">
                                        </div>
                                        <!-- <b-alert :show="dismissCountDown" dismissible :variant="variant" @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">{{ message }}</b-alert> -->

                                        <!--<p class="text-muted">Sign In to your account</p>-->
                                        <b-row>
                                            <b-col lg="12">
                                                <validation-provider name="usuario" :rules="{required: true}" v-slot="validationContext">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-button disabled variant="light">
                                                                <b-icon icon="person-fill"></b-icon>
                                                            </b-button>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Usuario" v-model="usuario"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                </validation-provider>
                                            </b-col>

                                            <b-col class="mt-3" lg="12">
                                                <validation-provider name="contraseña" :rules="{required: true}" v-slot="validationContext">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-button disabled variant="light">
                                                                <b-icon icon="lock-fill"></b-icon>
                                                            </b-button>
                                                        </b-input-group-prepend>
                                                        <b-form-input type="password" :state="getValidationState(validationContext)" placeholder="Contraseña" v-model="contrasena"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                </validation-provider>
                                            </b-col>

                                        </b-row>
                                        <template v-if="!overlayLogin">
                                            <b-row>
                                                <CCol col="12" class="text-center mt-3 mb-2">
                                                    <CButton color="dark" type="submit" class="px-4">Iniciar Sesión</CButton>
                                                </CCol>
                                            </b-row>
                                            <!-- <b-row>
                                            <CCol col="12" class="text-center">
                                                <CButton color="link" :to="{name:'Recuperar'}" class="px-0">Olvidaste tu Contraseña?</CButton>
                                            </CCol>
                                        </b-row> -->
                                        </template>
                                        <template v-if="overlayLogin">
                                            <b-overlay show rounded="lg" class="mt-5 mb-5" opacity="0.0">
                                                <template v-slot:overlay>
                                                    <div class="d-flex align-items-center ">
                                                        <b-spinner small type="grow" variant="secondary"></b-spinner>
                                                        <b-spinner type="grow" variant="dark"></b-spinner>
                                                        <b-spinner small type="grow" variant="secondary"></b-spinner>
                                                    </div>
                                                </template>
                                            </b-overlay>
                                        </template>
                                    </b-form>
                                </validation-observer>

                            </CCardBody>
                        </CCard>

                    </CCardGroup>
                </b-col>
            </transition>
        </b-row>
    </CContainer>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    name: "login",
    data() {
        return {
            usuario: '',
            contrasena: '',
            overlayLogin: false,

            render: false,

            datosCliente: {
                idCliente: "",
                razonSocial: "",
                path: "",
                logo: ""
            },
            direccionIp: '',
            navegador: '',
            sistemaOperativo: '',
            disabled: true,
        };
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        // countDownChanged(dismissCountDown) {
        //     this.dismissCountDown = dismissCountDown;
        // },
        login() {
            let me = this;
            me.overlayLogin = true;
            axios
                .post(CONSTANTES.URL_RUTA_SERVICIOS + "auth/login-cliente", {
                    usuario: me.usuario,
                    password: me.contrasena,
                    idCliente: me.datosCliente.idCliente,
                    direccionIp: me.direccionIp,
                    navegador: me.navegador,
                    sistemaOperativo: me.sistemaOperativo
                })
                .then(function (response) {
                    // if (response.data.token) {
                    //     localStorage.token = response.data.token;
                    //     // localStorage.settings = JSON.stringify(response.data.settings);
                    //     if (response.data.user.idTipoUsuario == 2) {
                    //         localStorage.uud_session = me.getCharacters(50) + response.data.user.idTipoPerfil + me.getCharacters(48) + response.data.user.idTipoUsuario + me.getCharacters(45) + response.data.user.idUsuario + me.getCharacters(42) + response.data.user.idCliente + me.getCharacters(32);
                    //         //mi idCliente: ",localStorage.uud_session.split("").splice(-33)[0],
                    //         // "idUsuario: " ,localStorage.uud_session.split("").splice(-76)[0],
                    //         // "idTipoUsuario:", localStorage.uud_session.split("").splice(-122)[0])

                    //         me.$router.push({
                    //             name: "Panel de Control",
                    //         });
                    //     }
                    // } else {

                    //     me.message = response.data.message;
                    //     me.dismissCountDown = me.dismissSecs;
                    // }
                    if (response.data.token) {
                        if (response.data.uidType == 'c2c4ef67-2198-40a3-9c31-adf97d4b88ef') {
                            localStorage.token = response.data.token;
                            localStorage.settings = JSON.stringify(response.data.settings);
                            me.$router.push({
                                name: "Panel de Control",
                            });
                        } else {
                            me.swat('error', 'Acceso denegado');
                            me.overlayLogin = false;
                        }
                    } else {
                        me.swat('error', response.data.message);
                        me.overlayLogin = false;
                    }
                    me.overlayLogin = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salisó mal');
                    me.overlayLogin = false;
                });
        },
        obtenerDatosAccesosSistema() {
            fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({
                    ip
                }) => {
                    this.direccionIp = ip;
                    let sistema = "SO desconocido";
                    if (navigator.userAgent.indexOf("Win") != -1) sistema = "Windows";
                    if (navigator.userAgent.indexOf("Mac") != -1) sistema = "Macintosh";
                    if (navigator.userAgent.indexOf("Linux") != -1) sistema = "Linux";
                    if (navigator.userAgent.indexOf("Android") != -1) sistema = "Android";
                    if (navigator.userAgent.indexOf("like Mac") != -1) sistema = "iOS";

                    this.sistemaOperativo = sistema;

                    let browser = (function () {
                        let test = function (regexp) {
                            return regexp.test(window.navigator.userAgent)
                        }
                        switch (true) {
                            case test(/edg/i):
                                return "Microsoft Edge";
                            case test(/trident/i):
                                return "Microsoft Internet Explorer";
                            case test(/firefox|fxios/i):
                                return "Mozilla Firefox";
                            case test(/opr\//i):
                                return "Opera";
                            case test(/ucbrowser/i):
                                return "UC Browser";
                            case test(/samsungbrowser/i):
                                return "Samsung Browser";
                            case test(/chrome|chromium|crios/i):
                                return "Google Chrome";
                            case test(/safari/i):
                                return "Apple Safari";
                            default:
                                return "Otro navegador";
                        }
                    })();
                    this.navegador = browser;
                    // this.obtenerDatosCliente();
                });

        },
        obtenerDatosCliente() {
            let me = this;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "auth/obtener-login-cliente", {
                    params: {
                        path: me.$route.params.rsCliente
                    },
                })
                .then(function (response) {

                    if (response.data.length == 0) {
                        me.$router.push({
                            name: "Login",
                        });
                    }
                    me.disabled = false;
                    me.datosCliente.logo = response.data[0].urlLogo
                    me.datosCliente.idCliente = response.data[0].idCliente
                    me.render = true
                })
                .catch(function (error) {
                    // me.swat('error','Algo salió mal')
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }

        // getCharacters(length) {
        //     var result = '';
        //     var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        //     var charactersLength = characters.length;
        //     for (var i = 0; i < length; i++) {
        //         result += characters.charAt(Math.floor(Math.random() * charactersLength));
        //     }
        //     return result;
        // }
    },
    watch: {
        '$route.params.rsCliente': function () {
            this.obtenerDatosCliente();
        }
    },
    mounted() {
        
        this.obtenerDatosCliente();
        this.obtenerDatosAccesosSistema();
        //this.obtenerDatosCliente();
    }
};
</script>

<style scoped>
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.bg-login {
    background-image: url('../../../public/img/background_login.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

</style>
